/* You can add global styles to this file, and also import other style files */
@import 'src/assets/scss/_color.scss';
/* Importing Bootstrap SCSS file. */
@import '/node_modules/bootstrap/scss/bootstrap';

/* Importing Datepicker SCSS file. */
@import '/node_modules/ngx-bootstrap/datepicker/bs-datepicker';

@import '/src/assets//scss/style';

@import '/src/assets/scss/override_bootstrap_class';
@import '/src/assets/scss/ngx_bootstrap_modal_custom_style';
@import '/src/assets//scss//override_ckeditor';
