.modal {
    display: flex !important;

    .partner-contact-modal {
        margin: auto;

        @media (min-width: 576px) {
            max-width: 300px;
        }

        .modal-content {
            border-radius: 10px;
            width: 560px;
            left: -96px;
            position: relative;
            display: flex;
            flex-direction: column;
        }
    }
}

.manual-payment-modal > .modal-content {
  width: 580px;

}
.booking-advance-search-modal{
    width: 660px;
}

.modal-successful {
    width: 350px;
}

.confirm-cancel-modal {
    width: 445px;

    .modal-content {
        border: 0px;
    }
}
