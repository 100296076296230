$color-nice-blue: #0b69a3;
$color-blue-tourwow: #146d99;
$color-grey-text-normal: #56697e;
$color-bluey-grey: #8a9bad;
$color-green-avaliable: #3bb54a;

$color-white: #fff;
$color-red: #ff4136;
$color-dark-light: #8a9bad;
$color-orange-text-note: #ff6704;
$color-dark-grey: #5c5c5e;

$color-grey-text-normal: #56697e;
$color-orange: #ffad00;
$color-blue-tourwow: #146d99;
$color-link: #2b66b1;

$primary:#0b69a3;
$danger:#ff4136;
$secondary:#56697e;