
//   $primary: #0b69a3;
//   $danger: #ff4136;
//   $secondary: #56697e;

.container {
    margin:0px;
}

.modal-transfer-commission-agency {
    width: 715px;

    .modal-content {
        height: 475px;
    }
}
.btn{
    min-width: 100px;
}
.btn-secondary{
    background-color:#56697e
}

.btn-secondary:hover{
    background-color:#56697e;
    border-color: #56697e;
}
.btn-outline-secondary{
    background-color:unset;
    border-color: #56697e;
    color:#56697e ;
}
.btn-outline-secondary:hover{
    background-color:unset;
    border-color: #56697e;
    color:#56697e ;
}
.btn-outline-primary:hover{
    background-color:unset;
    border-color: #0b69a3;
    color:#0b69a3 ;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: none;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
    color: #56697e;
    background-color: white;
    border-color: #56697e;
}

.btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #49494b;
    border-color: #434344;
    box-shadow: none;
}

.dropdown-item.active, .dropdown-item:active {
    color: #8a9bad;
    text-decoration: none;
    background-color: white;
}

.dropdown-item{
    color: #56697e;
}

.icon-arrow-down:before {
    content: "\2304";
    font-style: normal;
    font-size: 20px;
}

.modal .work-list-modal .modal-content {
  width: 850px;
}

.modal-dialog.auto-scroll > .modal-content {
    max-height: calc(100vh - 40px);
    overflow-y: auto;
}

.edit-lead-modal > .modal-content {
    width: 350px;
}

.width-500 > .modal-content {
    width: 500px;
}


.width-600 {
    max-width: 600px;

    .modal-content {
        width: 600px;
    }
}

.width-1000 {
    max-width: 1000px;

    .modal-content {
        width: 1000px;
    }
}
