@import './color';

.text- {
    &dark-grey {
        color: $color-dark-grey
    }
    &grey-text-normal {
        color: $color-grey-text-normal
    }
    &red {
        color: $color-red
    }
    &orange {
        color: $color-orange
    }
    &blue-tourwow {
        color: $color-blue-tourwow;
    }
    &dark-light {
        color: $color-dark-light;
    }

    &bold{
        font-weight: bold;
    }
}

.text-3xl{
    font-size: 24px;
}

.text-xxl {
    font-size: 20px;
}

.text-xl {
    font-size: 18px;
}

.text-lg {
    font-size: 16px;
}

.text-md {
    font-size: 14px;
}

.text-sm {
    font-size: 12px;
}

.text-xs {
    font-size: 10px;
}

.text-xxs {
    font-size: 8px;
}
.text-red {
    color:$danger
}
.text-green {
    color:$color-green-avaliable
}
.text-gray {
    color:$secondary
}
.text-light-gray{
    color:#8a9bad
}
.text-disabled {
    opacity: 0.35;
}

.link-disabled {
    pointer-events: none;
}

.text-underline{
    text-decoration: underline;
}

.cursor-pointer{
    cursor: pointer;
}

.required:after {
    content:" *";
    color: $color-red;
}
input[type=radio], input[type=checkbox] {
    accent-color:$primary;
}